import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ObligationDetails from './ObligationDetails';
import { useNavigate, useLocation } from "react-router-dom";

function Obligation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [labsList, setLabsList] = useState([]);
    // const [requestStatus, setRequestStatus] = useState(null);
    const [selectedLab, setSelectedLab] = useState(null);

    const token = decodeURI(location.search.split('=')[1]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/patient/v1/patient-lab-tests/list`, { headers: {
            "Content-Type": "application/json",
            "Accept-Language": "ar",
            'Authorization': `Bearer ${token}`
        }}).then(res => {
            setLabsList(res?.data?.data?.list);
            //setRequestStatus(res.data.request_status)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const acceptObligation = (id) => {
        setLabsList(() => {
            labsList?.map(lab => {
                if (lab.id === id) {
                    lab.is_approved = true;
                }
                return lab;
            });
            return labsList;
        })
        setShowModal(false);
    }

    const rejectObligation = (id) => {
        setLabsList(() => {
            labsList?.map(lab => {
                if (lab.id === id) {
                    lab.is_approved = false;
                }
                return lab;
            });
            return labsList;
        })
        setShowModal(false);
    }

    const confirm = () => {
        console.log(labsList.filter(lab => lab.is_approved).map(lab => lab.id))
        axios.post(`${process.env.REACT_APP_BASE_URL}/patient/v1/patient-lab-tests/confirm`, {
            approved_lab_tests: labsList.filter(lab => lab.is_approved).map(lab => lab.id),
        }, { headers: {
            "Content-Type": "application/json",
            "Accept-Language": "ar",
            'Authorization': `Bearer ${token}`
        }}).then(res => {
            navigate('/');
        });
    }

    if (!token) return <div className='error-container'><span className='error-msg'>No Token</span></div>;
    //console.log(labsList);

    return (
        <div>
            <div className='obligation-aproval-container'>
                <div className='obligation-aproval'>
                    <h2>موافقة التعهد</h2>
                    <img src="/images/obligation-aproval.png" alt="obligationAproval" />
                    <h3>{`تم تحديد الفحوصات الطبية من جانب الأخصائية`}</h3>
                    <p>{`يمكنك استعراض التفاصيل الخاصة بكل فحص والموافقة على تعهدات الفحوصات المناسبة لك.`}</p>
                    <div className='obligation-aproval-labtests'>
                        {
                            labsList?.length > 0 ? labsList?.map((labtest, index) => {
                                    return (
                                    <div key={index} className='labtest'>
                                        <h4>{labtest.lab_test_name}</h4>
                                        <p>السعر {labtest.lab_test_price} ريال { labtest.is_approved && <span className="stage">تم الموافقة على التعهد</span>}</p>
                                        <button className='details_btn' onClick={() => { setSelectedLab(labtest); setShowModal(true)}}>تفاصيل</button>
                                    </div>
                                    )
                                })
                            : <div className='no_obligation'>لا يوجد تعهدات</div>
                        }
                    </div>
                    {
                        labsList?.length > 0 && (
                            <div className='obligation-aproval-btn'>
                                <div className='action'>
                                    <button type='button' onClick={() => navigate('/')} >إلغاء</button>
                                    <button type='button' disabled={!labsList} onClick={() => confirm()} >موافق</button>
                                </div>
                            </div>
                        )
                    }
                    <div className='apps_icons_container'>
                        <a href='https://apps.apple.com/app/%D8%AC%D9%8A%D9%86%D9%88%D9%85/id1626282775' target="_blank" className='apps_icons' rel="noreferrer">
                            <img src='/images/ios.png' alt='appstore' />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=sa.squadio.genome' target="_blank" className='apps_icons' rel="noreferrer">
                            <img src='/images/android.png' alt='appstore' />
                        </a>
                    </div>
                </div>
            </div>
            <ObligationDetails 
                    show={showModal} 
                    close={() => setShowModal(false)} 
                    labtest={selectedLab} 
                    acceptObligation={(id) => acceptObligation(id)} 
                    rejectObligation={(id) => rejectObligation(id)}
            />
        </div>
  )
}

export default Obligation
